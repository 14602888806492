/**
 * @desc Файл для импорта плагинов leaflet
 */

import 'leaflet-plugins';
import 'leaflet-controls';

console.log('leaflet-initial');

L.LatLng.DEG_TO_RAD = 0.017453292519943295;

// fix для иконок, которые неправильно подтягиваются webpack'ом

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('../files/leaflet/marker-icon-2x.png'),
    iconUrl: require('../files/leaflet/marker-icon.png'),
    shadowUrl: require('../files/leaflet/marker-shadow.png'),
    iconSize: [32, 44],
    iconAnchor: [16, 44],
    popupAnchor: [1, -44],
    shadowAnchor: [19, 16],
    shadowSize: [42, 17]
});

L.Map.addInitHook(function() {

	var that = this
		, h
	;

	if (that.on) {
		that.on('click',    check_later);
		that.on('dblclick', function() { setTimeout( clear_h, 0 ); });
	}

	function check_later(e) {
		clear_h();

		h = setTimeout( check, 500 );

		function check() {
			that.fire('singleclick', L.Util.extend( e, { type: 'singleclick' }));
		}
	}

	function clear_h() {
		if (h != null) {
			clearTimeout( h );
			h = null;
		}
	}

});

//Extend Map._initControlPos to also create a topcenter-container
L.Map.prototype._initControlPos = function (_initControlPos) {
    return function () {
        //Original function/method
        _initControlPos.apply(this, arguments);

        //Adding new control-containers

        //topcenter is the same as the rest of control-containers
        this._controlCorners['topcenter'] = L.DomUtil.create('div', 'leaflet-top leaflet-center', this._controlContainer);

        //bottomcenter need an extra container to be placed at the bottom
        this._controlCorners['bottomcenter'] =
            L.DomUtil.create(
                'div',
                'leaflet-bottom leaflet-center',
                L.DomUtil.create('div', 'leaflet-control-bottomcenter', this._controlContainer)
            );
    };
}(L.Map.prototype._initControlPos);
